export const environment = {
  production: true,
  cdnUrl: 'https://cdn.methodem.dev/',
  apiUrl: 'https://api.methodem.dev/',
  frontUrl: 'https://www.methodem.dev/',
  portalUrl: 'https://portal.methodem.dev/',
  systemKey: 'methodem',
  salesWebsite: 'https://over.methodem.nl/',
  contactEmail: 'info@methodem.nl',
  contactPhone: '0031003333333',
  title: 'Methode M',
  slogan: 'Methode M \u22c6 D\u00e9 methode voor maatschappijleer op het vmbo',
  description: 'Kom, we gaan samen aan de slag met maatschappijleer! Methode M is actueel, interactief en vernieuwend.'
};